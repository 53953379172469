import { axios, initSentry, regexPatterns } from '@utils';
import Vue from 'vue';
import Cloudinarize from '@plugins/cloudinarize';
import KFormField from '@components/forms/form_field.vue';
import KInput from '@components/inputs/input.vue';
import SystemButton from '@components/buttons/system-button.vue';
import Box from '@components/box.vue';
import Loader from '@components/loader/loader.vue';
import Illustration from '@components/illustration.vue';
import initializeGoogleMaps from '@services/google_maps_initializer';
import LocationInput from '@components/location-input.vue';
import KDateInput from '@components/inputs/date-input.vue';

Vue.use(Cloudinarize);

document.addEventListener('DOMContentLoaded', () => {
  initSentry();
  initializeGoogleMaps();

  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    el: '.subscribe-vue',
    components: {
      KFormField,
      KInput,
      Box,
      Loader,
      SystemButton,
      Illustration,
      LocationInput,
      KDateInput,
    },

    data() {
      return {
        email: '',
        firstName: '',
        lastName: '',
        childDateOfBirth: '',
        locationData: null,
        error: '',
        errorUnknown: false,
        isSubscribed: false,
        loading: false,
        emailRegex: regexPatterns.email.regex,
        parentSubscriptionId: null,
        isDone: false,
      };
    },

    methods: {
      async submitForm() {
        this.loading = true;

        if (this.isSubscribed) {
          await this.addSubscriptionDetails();
        } else {
          await this.subscribe();
        }
      },

      async subscribe() {
        axios.post('/parent_subscriptions', {
          email: this.email,
        }).then((response) => {
          if (response.data.success && response.status === 201) {
            this.parentSubscriptionId = response.data.id;
            this.isSubscribed = true;

            if (typeof window.analytics !== 'undefined') {
              window.analytics.identify(this.email, {
                email: this.email,
                parent_subscription_id: this.parentSubscriptionId,
              });

              window.analytics.track('Form Submitted', {
                form_name: 'Parent Subscription',
                form_step: 'email',
                form_type: 'subscribe',
              });
            }

            this.$nextTick(() => {
              this.focusOnFirstName();
            });
          } else {
            this.errorUnknown = true;
            // Handle the errors from the server
            // this.error = response.data.errors.join(', ');
          }

          this.loading = false;
        }).catch((error) => this.handleErrorResponse(error));
      },

      async addSubscriptionDetails() {
        axios.put(`/parent_subscriptions/${this.parentSubscriptionId}`, {
          first_name: this.firstName,
          last_name: this.lastName,
          location_data: this.locationData,
          child_date_of_birth: this.childDateOfBirth,
        }).then((response) => {
          if (response.data.success && response.status === 200) {
            this.isDone = true;
            if (typeof window.analytics !== 'undefined') {
              window.analytics.track('Form Submitted', {
                form_name: 'Parent Subscription',
                form_step: 'details',
                form_type: 'subscribe',
              });
            }
          } else {
            this.errorUnknown = true;
          }

          this.loading = false;
        }).catch((error) => this.handleErrorResponse(error));
      },

      handleErrorResponse(error) {
        if (error.response?.status === 401) {
          this.error = error.response.data.message;
        } else {
          this.errorUnknown = true;
        }
      },

      onEmailChange() {
        if (this.error) {
          this.resetState();
        }
      },

      onModalClose() {
        this.email = '';
        this.resetState();
      },

      resetState() {
        this.error = '';
        this.firstName = '';
        this.lastName = '';
        this.locationData = null;
        this.childDateOfBirth = '';
        this.errorUnknown = false;
        this.isSubscribed = false;
        this.loading = false;
        this.parentSubscriptionId = null;
        this.isDone = false;
      },

      setLocation(location) {
        this.locationData = location;
      },

      focusOnFirstName() {
        if (this.$refs.firstNameInput) {
          this.$refs.firstNameInput.focusInput();
        }
      },
    },

    computed: {
      submitDisabled() {
        return this.loading
          || this.email.length === 0
          || !this.emailRegex.test(this.email)
          || this.errorUnknown
          || (!this.isSubscribed && this.error.length > 0);
      },

      formSubmitButtonLabel() {
        return this.isSubscribed ? 'Update details' : 'Subscribe';
      },
    },
  });
});
