<template>
  <k-input
    class="parents-map-filter"
    :class="{ 'input-with-left-label' : leftLabel }"
    type="search"
    :label="leftLabel"
  >
    <template #prefix>
      <span class="fal fa-location-circle"></span>
    </template>
    <gmap-autocomplete
      class="parents-map-filter__location-input"
      :placeholder="searchPlaceholder"
      selectFirstOnEnter
      :component-restrictions="{'country': ['us']}"
      :value="address"
      @focus="address=''"
      @place_changed="setPlace"
      @keypress.enter="$event.preventDefault();$event.stopPropagation();"
    />
    <template #suffix>
      <button
        @click="clearLocation"
        v-if="address"
        class="btn-clear-location"
      >
        <span class="fa-solid fa-circle-xmark"></span>
      </button>
    </template>
  </k-input>
</template>

<script>
import KInput from '@components/inputs/input.vue';
import { getLocationObjFromGooglePlace } from '@utils';

export default {

  components: {
    KInput,
  },

  props: {
    hideDefaultAddress: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'enter zipcode or address',
    },
    watchState: {
      type: Boolean,
      default: false,
    },
    leftLabel: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      highlightIcon: false,
      address: this.hideDefaultAddress ? null : this.$store?.state.search?.location?.targetAddress
          || this.$store?.state?.onboardingLocation?.targetAddress,
    };
  },

  methods: {
    setPlace(place) {
      if (!place) return;
      this.address = place.formatted_address;

      this.$emit('set-location', getLocationObjFromGooglePlace(place));
    },

    clearLocation() {
      this.address = '';
      this.$emit('set-location', null);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.onboardingLocation': function (newStatus) {
      if (this.watchState) this.address = newStatus?.targetAddress || null;
    },
  },
};
</script>

<style lang="scss" scoped>
.parents-map-filter {
  width: 100%;
  max-width: 400px;

  &__location-input {
    max-width: 360px;
    padding-left: 36px !important;
  }
}

.input-with-left-label {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 400px;
  }

  .k-input__input-field-wrapper {
    width: 269px;
  }

  .k-input__input-field {
    width: 100%;

    @media (min-width: 768px) {
      width: 269px;
    }
  }
}

.btn-clear-location {
  font-size: 18px;
  color: var(--gray-20);
  pointer-events: auto;
  cursor: pointer;
  background-color: white;
  border: none;
}
</style>

<style lang="scss">
.pac-container {
  z-index: 1051;
}

.input-with-left-label {
  .k-input-container__label {
    display: inline-block;
    width: 125px;
    padding: 9px 0 7px 17px;
    font-size: 16px;
    background-color: var(--gold-20);
    border: 1px solid var(--gray-50);
    border-right: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .k-input {
    display: inline-block;
    width: 100%;
    height: 42px;
    font-size: 16px;
    border-radius: 0 20px 20px 0;
  }
}
</style>
